import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useContext } from 'react';
import { Typography } from '@material-ui/core';
import ViewModuleIcon from '@material-ui/icons/ViewModuleOutlined';
import ViewListIcon from '@material-ui/icons/ViewListOutlined';
import { IconButton } from '../../../../../../components/IconButton';
import { LayoutContext, viewVariantsMap } from '../../../../../../components/LayoutContext';
import {
  PersonalInjuriesContext
} from '../../../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import { PageHeader, PageHeaderActions, PageHeaderTitle } from '../../../../../../components/Page';
import { SelectedItemsCount } from '../../../../../../components/SelectedItemsCount';
import { Tooltip } from '../../../../../../components/Tooltip';
import { VerticalDivider } from '../../../../../../components/VerticalDivider';

export const ActionsBar = ({ isMobile, filterOpenButton }) => {
  const { viewVariant, setViewVariant } = useContext(LayoutContext);
  const { meta, selectedIDs, bulkDelete } = useContext(PersonalInjuriesContext);

  const toggleViewVariant = (viewVariant) => () => {
    setViewVariant(viewVariant);
  };

  return (
    <PageHeader>
      <PageHeaderTitle>
        Personal Injury Requests
      </PageHeaderTitle>

      <PageHeaderActions disableSpacing endPosition>
        {!!selectedIDs.length &&
          <>
            <SelectedItemsCount length={selectedIDs.length} total={meta.total} />
            <VerticalDivider verticalGutters={1} horizontalGutters={1} />

            <Tooltip
              arrow
              title={<Typography>Delete selected items</Typography>}
            >
              <IconButton
                color="error"
                onClick={bulkDelete(selectedIDs)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <VerticalDivider verticalGutters={1} horizontalGutters={1} />
          </>
        }

        {!isMobile ? (
          <>
            <Tooltip arrow title="List view">
              <IconButton
                color={viewVariant === viewVariantsMap.list ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.list)}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Grid view">
              <IconButton
                edge="end"
                color={viewVariant === viewVariantsMap.grid ? 'primary' : 'default'}
                onClick={toggleViewVariant(viewVariantsMap.grid)}
              >
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <PageHeaderActions>
            {filterOpenButton}
          </PageHeaderActions>
        )}
      </PageHeaderActions>
    </PageHeader>
  );
};
