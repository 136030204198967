import { useSnackbar } from 'notistack';
import { createContext, useRef, useEffect, useReducer } from 'react';
import { isEqual, merge, omit } from 'lodash';
import { api } from '../../../../api';
import * as officesApi from '../../../../api/companies';
import { usePrevious } from '../../../../helpers/hooks';
import * as personalInjuriesApi from '../../../../api/personal-injury-requests';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useModal } from '../../../ModalsProvider';
import { initialState } from './initialState';
import { reducer } from './reducer';
import * as types from './types';

export const PersonalInjuriesContext = createContext();

export const PersonalInjuriesProvider = ({
  filter: filterProp = {},
  children
}) => {
  const [ state, dispatch ] = useReducer(reducer, merge({}, initialState, { filter: filterProp }));
  const {
    isFetched,
    isFetching,
    pagination,
    filter,
    personalInjuries,
    selectedIDs
  } = state;
  const prevFilter = usePrevious(filter);
  const cancelFetch = useRef(() => {});
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const fetchPersonalInjuries = (newFilter = {}) => {
    cancelFetch.current();

    dispatch({ type: types.FETCH_PERSONAL_INJURIES_REQUEST });

    personalInjuriesApi.fetchPersonalInjuries({
      params: { ...filter, ...newFilter },
      cancelToken: new api.CancelToken((cancel) => cancelFetch.current = cancel)
    }).then((data) => {
      dispatch({ type: types.FETCH_PERSONAL_INJURIES_SUCCESS, payload: data });
    });
  };

  const applyFilter = (newFilter) => {
    dispatch({ type: types.APPLY_FILTER, payload: newFilter });
  };

  const loadNextPage = () => {
    if (filter.page < pagination.last_page && pagination.total > 0) {
      fetchPersonalInjuries({ page: filter.page + 1 });
    }
  };

  const resetPersonalInjuries = (newFilter) => {
    dispatch({ type: types.RESET_PERSONAL_INJURIES, payload: newFilter });

    fetchPersonalInjuries({ page: 1, ...newFilter });
  };

  const addTicket = (personalInjury) => {
    dispatch({ type: types.ADD_PERSONAL_INJURY, payload: personalInjury });
  };

  const updatePersonalInjury = (personalInjury) => {
    dispatch({ type: types.UPDATE_PERSONAL_INJURY, payload: personalInjury });
  };

  const deletePersonalInjury = (personalInjuryId) => {
    dispatch({ type: types.DELETE_PERSONAL_INJURY, payload: personalInjuryId });
  };

  const bulkDelete = (IDs) => () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        officesApi.massDeleteOffices(IDs).then(() => {
          if (pagination.last_page > filter.page && selectedIDs.length < filter.per_page) {
            resetPersonalInjuries({ page: filter.page });
          } else if (personalInjuries.length - selectedIDs.length === 0 && filter.page !== 1) {
            resetPersonalInjuries({ page: filter.page - 1 });
          } else {
            dispatch({ type: types.BULK_DELETE, payload: IDs });
          }

          enqueueSnackbar('Successfully deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Items are not deleted', { variant: 'error' });
        });
      }
    });
  };

  const allItemsIsSelected = () => {
    return personalInjuries.length === selectedIDs.length;
  };

  const toggleItemSelection = (id) => {
    dispatch({ type: types.TOGGLE_ITEM_SELECTION, payload: id });
  };

  const toggleAllItemsSelection = () => {
    dispatch({ type: types.TOGGLE_ALL_ITEMS_SELECTION });
  };

  const providerValue = {
    isFetched,
    isFetching,
    defaultFilter: filterProp,
    personalInjuries,
    selectedIDs,
    filter: filter,
    meta: {
      ...filter,
      ...pagination
    },

    // functions
    resetPersonalInjuries,
    fetchPersonalInjuries,
    loadNextPage,
    applyFilter,
    addTicket,
    updatePersonalInjury,
    deletePersonalInjury,
    bulkDelete,
    allItemsIsSelected,
    toggleItemSelection,
    toggleAllItemsSelection
  };

  useEffect(() => {
    const newFilter = omit(filter, [ 'page' ]);

    if (!isEqual(omit(prevFilter, [ 'page' ]), newFilter)) {
      resetPersonalInjuries(newFilter);
    }
  }, [ prevFilter, filter ]);

  useEffect(() => {
    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <PersonalInjuriesContext.Provider value={providerValue}>
      {children}
    </PersonalInjuriesContext.Provider>
  );
};
