export const FETCH_PERSONAL_INJURIES_REQUEST = 'FETCH_PERSONAL_INJURIES_REQUEST';
export const FETCH_PERSONAL_INJURIES_SUCCESS = 'FETCH_PERSONAL_INJURIES_SUCCESS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const RESET_PERSONAL_INJURIES = 'RESET_PERSONAL_INJURIES';
export const ADD_PERSONAL_INJURY = 'ADD_PERSONAL_INJURY';
export const UPDATE_PERSONAL_INJURY = 'UPDATE_PERSONAL_INJURY';
export const DELETE_PERSONAL_INJURY = 'DELETE_PERSONAL_INJURY';
export const TOGGLE_ALL_ITEMS_SELECTION = 'TOGGLE_ALL_ITEMS_SELECTION';
export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const BULK_DELETE = 'BULK_DELETE';
