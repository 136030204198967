import { createHookReducer } from '../../../../helpers/createHookReducer';
import * as types from './types';

export const reducer = createHookReducer({
  [types.FETCH_PERSONAL_INJURIES_REQUEST]: (state) => {
    return { ...state, isFetching: true };
  },

  [types.FETCH_PERSONAL_INJURIES_SUCCESS]: (
    { filter, personalInjuries, ...state },
    { data: newPersonalInjury, pagination: { page, per_page, ...pagination } }
  ) => {
    return {
      ...state,
      isFetched: true,
      isFetching: false,
      filter: { ...filter, page, per_page },
      pagination,
      personalInjuries: (page > 1
        ? personalInjuries.filter(({ id }) => {
          return !newPersonalInjury.find((loadedClaim) => id === loadedClaim.id);
        }).concat(newPersonalInjury)
        : newPersonalInjury
      )
    };
  },

  [types.APPLY_FILTER]: ({ filter, ...state }, newFilter = {}) => {
    return {
      ...state,

      filter: { ...filter, ...newFilter }
    };
  },

  [types.RESET_PERSONAL_INJURIES]: ({ filter, ...state }, newFilter) => {
    return {
      ...state,

      isFetched: false,
      filter: { ...filter, ...newFilter, page: 1 }
    };
  },

  [types.UPDATE_PERSONAL_INJURY]: (state, personalInjury) => {
    return {
      ...state,

      personalInjuries: state.personalInjuries.map((item) => {
        return item.id === personalInjury.id ? { ...item, ...personalInjury } : item;
      })
    };
  },

  [types.ADD_PERSONAL_INJURY]: (state, personalInjury) => {
    const { pagination, filter, personalInjuries } = state;
    const total = pagination.total + 1;
    const page = Math.ceil((personalInjuries.length + 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      personalInjuries: [ personalInjury, ...personalInjuries ]
    };
  },

  [types.DELETE_PERSONAL_INJURY]: (state, personalInjuryID) => {
    const { pagination, filter, personalInjuries, selectedIDs } = state;
    const filteredPersonalInjuries = personalInjuries.filter(({ id }) => personalInjuryID !== id);

    if (filteredPersonalInjuries.length === personalInjuries.length) {
      return state;
    }

    const total = pagination.total - 1;
    const page = Math.ceil((personalInjuries.length - 1) / filter.per_page);
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      pagination: { ...pagination, total, last_page },
      filter: { ...filter, page },
      personalInjuries: filteredPersonalInjuries,
      selectedIDs: selectedIDs.filter((id) => id !== personalInjuryID)
    };
  },

  [types.TOGGLE_ALL_ITEMS_SELECTION]: ({ personalInjuries, selectedIDs, ...state }) => {
    const allItemsIsSelected = personalInjuries.length === selectedIDs.length;

    return {
      ...state,

      personalInjuries,
      selectedIDs: allItemsIsSelected ? [] : personalInjuries.map((item) => item.id)
    };
  },

  [types.TOGGLE_ITEM_SELECTION]: ({ selectedIDs, ...state }, id) => {
    return {
      ...state,

      selectedIDs: selectedIDs.indexOf(id) !== -1
        ? selectedIDs.filter((item) => item !== id)
        : selectedIDs.concat(id)
    };
  },

  [types.BULK_DELETE]: ({ personalInjuries, selectedIDs, pagination, filter, ...state }, IDs) => {
    const total = pagination.total - IDs.length;
    const last_page = Math.ceil(total / filter.per_page);

    return {
      ...state,

      filter,
      selectedIDs: [],
      pagination: { ...pagination, total, last_page },
      personalInjuries: personalInjuries.filter((pi) => !IDs.includes(pi.id))
    };
  }
});
