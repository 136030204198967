import { Paper } from '@material-ui/core';
import { useContext } from 'react';
import { ListRow, ListRowCell, ListRowCheckbox } from '../../../../../../../../components/ListRow';
import {
  PersonalInjuriesContext
} from '../../../../../../../../components/medical/personal-injury-requests/PersonalInjuriesProvider';
import { columnsWidths } from '../listConfig';

export const TableHeader = () => {
  const { selectedIDs, toggleAllItemsSelection, allItemsIsSelected } = useContext(PersonalInjuriesContext);
  const isAllItemsSelected = allItemsIsSelected();

  return (
    <Paper elevation={0}>
      <ListRow header>
        <ListRowCheckbox
          checked={isAllItemsSelected}
          indeterminate={!!selectedIDs.length && !isAllItemsSelected}
          onClick={toggleAllItemsSelection}
        />

        <ListRowCell grow flexBasis={columnsWidths.name}>
          Full Name
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.status}>
          Status
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.creationDate}>
          Creation date
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.latestUpdates}>
          Latest updates
        </ListRowCell>

        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>
    </Paper>
  );
};
